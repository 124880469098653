import React, { useEffect, useState } from 'react';
import appStore from '../../store/index';
import { getWebTicketPosition, abtWebCloseTicket } from '../../utils/abtSocketLibClient';

const MobileWaitDecisionScreen = () => {
  const [ticketPosition, setTicketPosition] = useState(0);
  const controlSocket = appStore.useState((s) => s.controlSocket);
  const currentTicketId = appStore.useState((s) => s.currentTicketId);
  const appConfig = appStore.useState((s) => s.appConfig);
  const authUser = appStore.useState((s) => s.authUser);
  const appParams = appStore.useState((s) => s.appParams);
  const runningLines = appStore.useState((s) => s.runningLines);
  console.log('ticketPosition === ', ticketPosition);
  const getTicketPosition = async () => {
    if (appConfig.queueId && authUser.userId) {
      const answer = await getWebTicketPosition(appParams.qId || appConfig.queueId, controlSocket);
      const { data } = answer;
      setTicketPosition(Number(data) - 1);
    }
  };

  const stopWaiting = async () => {
    await abtWebCloseTicket(controlSocket, 'client_close');
    window.localStorage.removeItem('ticketId');
    window.location = appParams.backUrl;
  };

  useEffect(() => {
    getTicketPosition();
    const posInterval = setInterval(() => {
      getTicketPosition();
    }, 4000);

    return () => clearInterval(posInterval);
  }, [currentTicketId]);

  useEffect(() => {
    const marquee = document.getElementById('marquee');
    if (!marquee) return;
    const animateMarquee = (el, duration) => {
      const innerEl = el.querySelector('.marquee__inner');
      const innerWidth = innerEl.offsetWidth;
      let start = performance.now();
      let progress;
      let translateX;
      let i = 0;
      const realWidth = marquee.offsetWidth;
      let currentOffset = realWidth;

      requestAnimationFrame(function step(now) {
        progress = (now - start) / duration;
        if (progress > 1) {
          progress %= 1;
          i += 1;
          if (i > runningLines.length - 1) {
            i = 0;
          }
          innerEl.innerText = runningLines[i].text;
          start = now;
          currentOffset = realWidth;
        }
        translateX = (innerWidth + realWidth) * progress;
        currentOffset = realWidth - translateX;
        innerEl.style.transform = `translate3d(${currentOffset}px, 0 , 0)`;
        // cloneEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        requestAnimationFrame(step);
      });
    };

    animateMarquee(marquee, 15000);
  }, []);

  return (
    <>
      <div className="mobile-welcome">
        <div className="mobile-welcome__title">Ваш запрос поставлен на паузу</div>
        <div className="mobile-welcome__subtitle" />
        <p className="text-center mt-1">
          Специалист уточняет информацию
        </p>
        <button className="button button__accent mobile-welcome__leave-button" type="button" onClick={stopWaiting}>
          <h4>Не ждать ответа и оставить заявку</h4>
        </button>
      </div>
      {runningLines.length > 0
       && (
       <div className="footer" id="marquee">
         <div className="marquee__inner">{runningLines[0].text}</div>
       </div>
       )}
    </>
  );
};

export default MobileWaitDecisionScreen;
