import React, { useState } from 'react';

import { withOrientationChange } from 'react-device-detect';

import MobileMediaPlayer from '../MobileMediaPlayer';
import MobileChat from '../MobileChat';

const MobileActiveCallScreen = () => {
  // eslint-disable-next-line no-unused-vars
  const [showChat, setShowChat] = useState(false);


  return (
    <div className="mobile-active-call">
      <MobileMediaPlayer
        setShowChat={setShowChat}
        showChat={showChat}
      />
      <MobileChat showChat={showChat} setShowChat={setShowChat} />
    </div>
  );
};
export default withOrientationChange(MobileActiveCallScreen);
