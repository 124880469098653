export default (str) => {
  let newstr = str.replace(/[' ']+/g, '-').toLowerCase();

  const ru = new Map([
    ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'],
    ['є', 'e'], ['ё', 'e'], ['ж', 'j'], ['з', 'z'], ['и', 'i'], ['ї', 'yi'], ['й', 'i'],
    ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
    ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'h'], ['ц', 'c'], ['ч', 'ch'],
    ['ш', 'sh'], ['щ', 'shch'], ['ы', 'y'], ['э', 'e'], ['ю', 'u'], ['я', 'ya'],
  ]);

  newstr = str.replace(/[ъь]+/g, '');

  return Array.from(newstr)
    .reduce((s, l) => s + (ru.get(l) === undefined || ru.get(l.toLowerCase() === undefined) ? l : ru.get(l.toLowerCase()).toUpperCase()), '');
};
