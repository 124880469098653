/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { ReactComponent as MicIcon } from '../images/icons/mic.svg';
import { ReactComponent as MicOffIcon } from '../images/icons/mic_off.svg';
import { ReactComponent as CameraIcon } from '../images/icons/camera.svg';
import { ReactComponent as CameraOffIcon } from '../images/icons/camera_off.svg';
import { ReactComponent as ChatIcon } from '../images/icons/chat.svg';
import { ReactComponent as EndCallIcon } from '../images/icons/endcall.svg';
import videoLoader from '../images/videoLoader.gif';
import { LocalVideo, Video } from '../store/infinity/Video';
import { stopCall } from '../store/infinity';
import { hasVideo, toggle } from '../store/infinity/call';

const MediaPlayer = ({ setShowChat, showChat }) => {
  const [muted, setMuted] = useState(false);
  const [showSelfCam, setShowSelfCam] = useState(hasVideo);

  const toggleChat = () => { setShowChat(!showChat); };

  const toogleSelfCam = async (e) => {
    toggle('video');
    setShowSelfCam(!showSelfCam);
  };

  const toggleMicrophone = (e) => {
    toggle('audio');
    setMuted(!muted);
  };

  return (
    <>
      <div
        className={`media__player-remote`}
        style={{ overflow: 'hidden' }}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <Video
          poster={videoLoader}
          loop
          width="100%"
          disablePictureInPicture
          height="100%"
          onClick={useCallback((e) => { e.preventDefault(); }, [])}
          style={useMemo(() => ({ borderRadius: '0px' }), [])}
        />

        <div className="media__player-controls">
          {/* <Timer type="Timer" active tick={1000} /> */}
          <button type="button" className="media__player-controls-button" onClick={toogleSelfCam}>
            {showSelfCam ? <CameraIcon /> : <CameraOffIcon />}
            {/* <CameraIcon /> */}
          </button>
          <button type="button" className="media__player-controls-button" onClick={toggleMicrophone}>
            {muted ? <MicOffIcon /> : <MicIcon />}
            {/* <MicIcon /> */}
          </button>
          <button type="button" className={`media__player-controls-button ${(showChat) && 'media__player-controls-button button-green'}`} onClick={toggleChat}>
            <ChatIcon />
          </button>
          <button type="button" className="media__player-controls-button button-red" onClick={stopCall}>
            <EndCallIcon />
          </button>
        </div>

      </div>
      {showSelfCam && (
        <Draggable bounds="parent">
          <div className="media__player-self">
            <LocalVideo
              poster={videoLoader}
              controls={false}
              autoPlay
              disablePictureInPicture
              loop
              muted
              width="100%"
              height="100%"
              id="selfVideo"
              onClick={(e) => { e.preventDefault(); }}
            />
          </div>
        </Draggable>
      )}
    </>
  );
};

MediaPlayer.propTypes = {
  showChat: PropTypes.bool.isRequired,
  setShowChat: PropTypes.func.isRequired,
};

export default MediaPlayer;
