import axios from 'axios';
import appStore, { updateAppStore } from '../store';

export default async () => {
  try {
    const { appConfig, mediaServerStatus } = appStore.getRawState();
    const serverUrl = `https://${appConfig.serverHost}`;
    const statusReq = await axios.get(`${serverUrl}/api/media/status`);
    const { data: { status } } = statusReq;
    if (Boolean(status) !== mediaServerStatus) {
      updateAppStore({ mediaServerStatus: status });
    }
  } catch (e) {
    updateAppStore({ mediaServerStatus: false });
  }
};
