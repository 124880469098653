import { v4 } from "uuid";
import { PhoneStore } from "./store";

let ws, pingInterval, subscribeInterval;

export function getWs() {
  return ws;
}

export async function subscribeEvents(domain, login, password, sipDomain, callId) {
  ws = new WebSocket(`wss://${domain}/ws`);

  await new Promise((res) => ws.onopen = res);

  ws.send(JSON.stringify([
    'setup',
    {
      qid: v4(),
      capabilities: [
        { key: 'rest' },
        { key: 'subscr' },
      ],
    }
  ]));
  ws.send(JSON.stringify([
    'login',
    {
      qid: v4(),
      login,
      pwd: password,
      td: sipDomain,
    }
  ]));

  pingInterval = setInterval(() => {
    ws.send(JSON.stringify([
      'ping',
      {
        qid: v4(),
      }
    ]));
  }, 10000);

  const subscribe = () => {
    ws.send(JSON.stringify([
      "subscribe",
      {
        "objects": [
          "/rest/v1/model/smart_ivr/history/FileOperations",
        ],
        "exargs": {
          "filter": [ "==", [ "property", "call_id" ], [ "const", callId ] ]
        },
        "events": [ "modelevents.data_changed" ],
        "expires": 600,
        "qid": v4(),
      },
    ]));
  }

  subscribe()

  subscribeInterval = setInterval(subscribe, 1000 * 60 * 8);

  ws.onmessage = (e) => {
    const [event, data] = JSON.parse(e.data);

    if (event !== 'notify') {
      return;
    }

    // if (data?.data?.entity?.operationKind === 'scan_request') {
    //   scan({
    //     uploadfileLink: `https://${domain}/rest/v1/model/smart_ivr/CallInfoCards/${data.data.entity.parent_id}/files`,
    //     notifyuploadLink: `https://${domain}/rest/v1/model/smart_ivr/history/FileOperations`,
    //     call_id: callId,
    //     author_id: data?.data?.entity.author_id,
    //     login,
    //     pwd: password,
    //     sipDomain,
    //     domain,
    //   });
    // }

    // if (data?.data?.entity?.operationKind === 'print_request') {
    //   print({ link: data?.data?.entity?.link, login, pwd: password, sipDomain, domain });
    // }

    if (data?.data?.entity?.state === 'process') {
      console.log("TEST================================");

      PhoneStore.update((s) => {
        s.isAccepted = true;
      })
    }
    
  }
}

export function unsubscribeEvents() {
  if (!ws) {
    return;
  }

  if (ws) {
    try {
      ws.close(); 
    } catch (error) {
      
    }
    ws = null;
  }

  if (subscribeInterval) {
    clearInterval(subscribeInterval);
  }

  if (pingInterval) {
    clearInterval(pingInterval);
  }
}
