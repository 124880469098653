import React, { useEffect } from 'react';
import appStore from '../../store/index';
import { abtWebCloseTicket } from '../../utils/abtSocketLibClient';

const WaitingScreen = () => {
  const controlSocket = appStore.useState((s) => s.controlSocket);
  const appParams = appStore.useState((s) => s.appParams);
  const runningLines = appStore.useState((s) => s.runningLines);


  const stopWaiting = async () => {
    await abtWebCloseTicket(controlSocket, 'client_close');
    window.localStorage.removeItem('ticketId');
    window.location = appParams.backUrl;
  };

  useEffect(() => {
    const marquee = document.getElementById('marquee');
    if (!marquee) return;
    const animateMarquee = (el, duration) => {
      const innerEl = el.querySelector('.marquee__inner');
      const innerWidth = innerEl.offsetWidth;
      let start = performance.now();
      let progress;
      let translateX;
      let i = 0;
      const realWidth = marquee.offsetWidth;
      let currentOffset = realWidth;

      requestAnimationFrame(function step(now) {
        progress = (now - start) / duration;
        if (progress > 1) {
          progress %= 1;
          i += 1;
          if (i > runningLines.length - 1) {
            i = 0;
          }
          innerEl.innerText = runningLines[i].text;
          start = now;
          currentOffset = realWidth;
        }
        translateX = (innerWidth + realWidth) * progress;
        currentOffset = realWidth - translateX;
        innerEl.style.transform = `translate3d(${currentOffset}px, 0 , 0)`;
        // cloneEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
        requestAnimationFrame(step);
      });
    };

    animateMarquee(marquee, 35000);
  }, []);

  console.log('runningLines', runningLines);

  return (
    <>
      <div className="welcome">
        <div className="welcome__title">Добро пожаловать в систему</div>
        <div className="welcome__subtitle">Видеоконсультант</div>
        <p className="text-center mb-4">
          Эксперт ДЭК лично ответит на интересующие Вас вопросы.
          <br />
          Активировать камеру можно по желанию.
        </p>
        <button className="button button__grey button__leave" type="button" onClick={stopWaiting}>
          <h4>Не ждать ответа и оставить заявку</h4>
        </button>
      </div>
      {runningLines.length > 0
       && (
       <div className="footer" id="marquee">
         <div className="marquee__inner">{runningLines[0].text}</div>
       </div>
       )}
    </>
  );
};

export default WaitingScreen;
