import React,
{
  useState, useEffect, useRef, useCallback,
} from 'react';
import { Anchor } from 'grommet';
import moment from 'moment';
import 'moment/locale/ru';
import axios from 'axios';
import ImageViewer from 'react-simple-image-viewer';
// import { FileIcon, defaultStyles } from 'react-file-icon';
// import { Language } from 'grommet-icons';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';
import randomId from '../utils/randomId';
import bytesToSize from '../utils/bytesToSize';
import appStore from '../store';
import clip from '../images/ChatIcons/eva_attach-fill.svg';
import send from '../images/ChatIcons/send-plane-fill.svg';
import toLatin from '../utils/toLatin';
import DownloadIcon from '../images/icons/download.png';
import CloseIcon from '../images/icons/close.png';

moment.locale('ru');

const ChatComponent = (props) => {
  const { inScreenShare, showChat } = props;
  const ovdSession = appStore.useState((s) => s.ovdSession);
  const ovdSessionId = appStore.useState((s) => s.ovdSessionId);
  const currentChatSession = appStore.useState((s) => s.currentChatSession);
  const authUser = appStore.useState((s) => s.authUser);
  const appConfig = appStore.useState((s) => s.appConfig);
  const { serverHost } = appConfig;

  const { userType } = authUser;
  const [allowChatFile, setAllowChatFile] = useState(false);
  const [allowChatMessage, setAllowChatMessage] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const MessageInput = useRef(null);
  const bodyMessage = useRef(null);

  const openImageViewer = useCallback((link) => {
    setCurrentImage(link);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage('');
    setIsViewerOpen(false);
  };

  useEffect(() => {
    if (!currentChatSession.length) {
      // onChatMessage({
      //   id: randomId(), time: moment(), type: 'system', text: 'Начало звонка',
      // });
    }
  }, []);

  useEffect(() => {
    if (ovdSession) {
      setAllowChatFile(true);
      setAllowChatMessage(true);
    }
  }, [ovdSession]);

  useEffect(() => {
    bodyMessage.current.scrollTo(0, bodyMessage.current.scrollHeight + 1000);
  }, [showChat]);

  const sendMessage = () => {
    if (!allowChatMessage) return;
    if (MessageInput.current.value === '') return;
    const message = {
      id: randomId(),
      time: moment().toISOString(true),
      type: userType,
      text: MessageInput.current.value,
    };
    ovdSession.signal({
      data: JSON.stringify(message),
      to: [],
      type: 'chat-message',
    });
    MessageInput.current.value = '';
  };

  const onEnterPress = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) sendMessage();
  };

  const uploadFile = async (form) => {
    const formData = new FormData();
    const { elements } = form;
    const { name } = elements[0].files[0];
    const newName = toLatin(name);

    const file = new File([elements[0].files[0]], newName.toLowerCase(), {
      type: elements[0].files[0].type,
      lastModified: elements[0].files[0].lastModified,
    });
    setAllowChatFile(false);
    formData.append('file', file);
    formData.append('sessionId', ovdSessionId);

    const res = await axios({
      method: 'post',
      url: `https://${serverHost}/api/media/upload`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const message = {
      id: randomId(),
      time: moment().toISOString(true),
      type: userType,
      file: { ...res.data.file },
    };
    if (res.status === 200) {
      setAllowChatFile(true);
      ovdSession.signal({
        data: JSON.stringify(message),
        to: [],
        type: 'chat-message',
      });
    }
  };

  const addFile = () => {
    if (!allowChatFile) return;
    const form = document.createElement('form');
    form.enctype = 'multipart/form-data';
    form.method = 'post';
    const el = document.createElement('INPUT');
    el.type = 'file';
    el.name = 'uploadFile';
    el.addEventListener('change', async () => { await uploadFile(form); });
    form.appendChild(el);
    el.click();
  };

  const downloadFile = (e, downloadLink) => {
    e.preventDefault();
    e.stopPropagation();
    fetch(downloadLink, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then((buffer) => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          const hrefParts = downloadLink.split('/');
          link.setAttribute('download', hrefParts[hrefParts.length - 1]); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    bodyMessage.current.scrollTo(0, bodyMessage.current.scrollHeight + 1000);
  }, [currentChatSession]);

  const renderMessageType = (message) => {
    let msgComponent = <Linkify options={{ target: '_blank' }}><p>{message.text}</p></Linkify>;
    if (message.file) {
      const {
        downloadLink, name, size,
      } = message.file;
      // mimetype из message.file
      // const fileType = mimetype.split('/')[0];
      // const isVideo = (fileType === 'video');
      const nameParts = name.split('.');
      const fileExtension = nameParts[nameParts.length - 1];
      const isImage = (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png');
      const fName = (nameParts[0].length > 13) ? `${nameParts[0].substring(0, 10)}...` : nameParts[0];
      const finalName = `${fName}.${fileExtension} (${bytesToSize(size)})`;
      // const fileStyles = defaultStyles[fileExtension];
      msgComponent = (
        <p>
          <Anchor
            href={downloadLink}
            rel="noopener noreferrer"
            onClick={(e) => downloadFile(e, downloadLink)}
            style={{ color: (message.type === userType) ? '#454545' : '#fff' }}
          >
            <h6 style={{ margin: '8px 8px 8px 0px' }}>{finalName}</h6>
          </Anchor>
          {isImage && showChat && (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => openImageViewer(downloadLink)}
              onKeyDown={() => openImageViewer(downloadLink)}
            >
              <img
                src={downloadLink}
                alt={name}
                style={{
                  marginTop: 5, marginBottom: 5, maxWidth: '100%', width: '100%',
                }}
              />
            </div>
          )}
          {/* {isVideo && (
            <video
              src={downloadLink}
              style={{
                marginTop: 5, marginBottom: 5, maxWidth: '100%', width: '100%', zIndex: 4444,
              }}
            >
              <track kind="captions" />
            </video>
          )} */}
        </p>
      );
    }
    return msgComponent;
  };

  const renderMessages = () => currentChatSession.map((message) => (
    <div
      className={`message ${(message.type === userType) && 'message__self'}`}
      key={message.id}
    >
      {renderMessageType(message)}
      <dd>{moment(message.time).format('HH:mm')}</dd>
    </div>
  ));

  const buildChatClass = () => {
    let chatClass;

    if (showChat) chatClass = 'chat__box-absolute';
    else if (inScreenShare) chatClass = 'chat__box-not-active';
    else chatClass = 'chat__box-overlay';

    return `chat__box ${chatClass}`;
  };

  return (
    <div className={buildChatClass()}>
      <div className={`chat__box__talk ${(!showChat) ? 'chat__box__talk-not-active' : ''}`} ref={bodyMessage}>
        <div className="messages-tolk-atel" />
        <div className="messages-box">
          {renderMessages()}
        </div>
        <iframe title="fileLoad" style={{ display: 'none' }} name="hiddenIframe" id="hiddenIframe" />
      </div>
      <div className={`chat__box-form ${(!showChat) ? 'chat__box-form-not-active' : ''}`}>
        <input type="image" src={clip} alt="addFile" color={allowChatFile ? '#454545' : '#353535'} onClick={addFile} />
        <input className="chat__box-form-text" placeholder="Введите сообщение" size="chatMessage" style={{ fontWeight: 'normal' }} ref={MessageInput} onKeyPress={onEnterPress} />
        <input type="image" src={send} alt="sendMessage" style={allowChatMessage ? { opacity: 1 } : { opacity: 0.5 }} color="rgba(2, 195, 154, 0.75)" onClick={sendMessage} />
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={[currentImage]}
          backgroundStyle={{ zIndex: 110, backgroundColor: '#000000BF' }}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
          closeComponent={(
            <div
              className="chat__box-preview-control"
            >
              <button
                className="chat__box-preview-control_download"
                type="button"
                onClick={(e) => downloadFile(e, currentImage)}
              >
                <img src={DownloadIcon} alt="" />
              </button>
              <button
                className="chat__box-preview-control_close"
                type="button"
                onClick={closeImageViewer}
              >
                <img src={CloseIcon} alt="" />
              </button>
            </div>
          )}
        />
      )}
    </div>
  );
};

ChatComponent.propTypes = {
  inScreenShare: PropTypes.bool.isRequired,
  showChat: PropTypes.bool.isRequired,
};

export default ChatComponent;
