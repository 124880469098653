import { Inviter, SessionState, UserAgent } from "sip.js";
import { PhoneStore } from "./store";
import { sdpModifier } from "./sdpModifier";
import { getUserAgent } from "./phone";
import { onSessionDisconnect } from "..";
import { setInviter, updateStreams } from "./display-elements";

let inviter = null;
let accepted = false;

export function hasVideo() {
  return inviter.sessionDescriptionHandler.peerConnection.getSenders().some((s) => s.track.kind === 'video');
}

export function toggle(kind) {
  const senders = inviter.sessionDescriptionHandler.peerConnection.getSenders();

  for (const sender of senders) {
    if (sender.track.kind === kind) {
      sender.track.enabled = !sender.track.enabled;
      return;
    }
  }
}

function stateListener(state) {
  PhoneStore.update((s) => { s.callStatus = state; });

  if (state === SessionState.Established) {
    updateStreams();
  }

  if (state === SessionState.Terminated) {
    inviter = null;
    onSessionDisconnect(accepted);
  }
}

async function settingInviter(onRefer) {
  if (!inviter.delegate) {
    inviter.delegate = {};
  }
  setInviter(inviter);

  inviter.sessionDescriptionHandlerModifiers = [sdpModifier];
  inviter.delegate.onRefer = async (referral) => {
    accepted = true;
    await referral.accept();
    updateStreams();

    inviter.stateChange.removeListener(stateListener);

    inviter = referral.makeInviter({});
    PhoneStore.update((s) => { s.isAccepted = true });

    const res = await settingInviter(onRefer);

    onRefer(res.message.callId);
  }

  PhoneStore.update((s) => { s.callStatus = inviter.state; });
  inviter.stateChange.addListener(stateListener);

  const res = await inviter.invite({
    sessionDescriptionHandlerOptions: {
      constraints: {
        audio: true, video: true,
      },
    },
  });

  return res;
}

export async function call(num, sipDomain, onRefer) {
  PhoneStore.update((s) => {
    s.isAccepted = false;
  });
  const userAgent = getUserAgent();

  const uri = UserAgent.makeURI("sip:" + num + "@" + sipDomain);
  if (!uri || !userAgent) {
    return;
  }
  inviter = new Inviter(userAgent, uri, { earlyMedia: true });

  const res = await settingInviter(onRefer);

  return res.message.callId;
}

export async function end() {
  console.log('END CALL');
  if (inviter) {
    try {
      await inviter.bye(); 
    } catch (error) {
      console.log('by error:', error);
    }
    inviter = null;
  }
  PhoneStore.update((s) => { s.callStatus = SessionState.Initial; });
}