import { Registerer, UserAgent } from "sip.js";
import { PhoneStore } from "./store";

let userAgent = null;
let registerer = null;

export function getUserAgent() {
  return userAgent;
}

export function registry(domain, sipDomain, username, password) {
  PhoneStore.update((s) => {
    s.domain = domain;
  });

  userAgent = new UserAgent({
    uri: UserAgent.makeURI('sip:' + username + '@' + sipDomain),
    displayName: username,
    authorizationUsername: username,
    authorizationPassword: password,
    transportOptions: {
      server: "wss://" + domain + ":" + 5063,
    },
    logLevel: "error",
    sessionDescriptionHandlerFactoryOptions: {
      iceGatheringTimeout: 1000
    },
    delegate: {},
    userAgentString: "alphamatica/web"
  });


  return new Promise((res, rej) => {
    userAgent.transport.stateChange.addListener((data) => {  
      PhoneStore.update((s) => {
        s.netStatus = data;
      });
  
      if (data === 'Connected' && userAgent) {
        registerer = new Registerer(userAgent, { expires: 60 })
    
        registerer.stateChange.addListener((data) => {
          PhoneStore.update((s) => {
            s.regStatus = data; 
          })
        });
    
        registerer.register({
          requestDelegate: {
            onAccept() {
              console.log('register accepted');
              res()
              // TODO: Подключение второго сокета
            },
            onReject(response) {
              console.log('reject register');
              rej(response)
              PhoneStore.update((s) => {
                s.errorRegistration = response;
              });
            },
          }
        })
      }
    });
    
    const superOnMessage = userAgent.transport.onMessage;
    userAgent.transport.onMessage = (msg) => {
      if (superOnMessage && userAgent) {
        superOnMessage.call(userAgent.transport, msg);
      }
    };
    
    window.addEventListener('beforeunload', logout);
    return userAgent.start();
  })
}

export async function logout() {
  console.log('LOGOUT');
  if (registerer) {
    try {
        await registerer.unregister();
    } catch (error) {
      
    }
    try {
        await registerer.dispose();
    } catch (error) {
      
    }
  } 
  registerer = null;

  if (userAgent) {
    userAgent = null;
  }
  window.removeEventListener('beforeunload', logout);
}
