import React, { useEffect, useState } from 'react';

import {
  withOrientationChange,
} from 'react-device-detect';

import ChatComponent from '../Chat';
import MediaPlayer from '../MediaPlayer';

const ActiveCallScreen = () => {
  const [showChat, setShowChat] = useState(false);

  return (
    <div className="active-call__screen">
      <MediaPlayer
        setShowChat={setShowChat}
        showChat={showChat}
      />
      <ChatComponent showChat={showChat} />
    </div>
  );
};
export default withOrientationChange(ActiveCallScreen);
