import React, { useEffect, useRef } from "react";
import { addRemoteDisplayElement, removeRemoteDisplayElement, addLocaldisplayElement, removeLocalDisplayElement } from "./display-elements";

export const Video = React.memo((props) => {
  const ref = useRef();
  console.log('test');

  useEffect(() => {
    console.log('test');
    if (!ref.current) {
      return;
    }

    const id = addRemoteDisplayElement(ref.current);

    return () => removeRemoteDisplayElement(id);
  }, []);

  return <video {...props} autoPlay playsInline ref={ref} />
})

export const LocalVideo = React.memo((props) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const id = addLocaldisplayElement(ref.current);

    return () => removeLocalDisplayElement(id);
  }, []);

  return <video {...props} autoPlay playsInline ref={ref} />
})