const prepareResponse = (req, data = {}) => ({
  success: (req.status === 'ok'),
  data,
});

const doRequest = async (command, ws) => {
  try {
    const req = await ws.sendMessage(command);
    return prepareResponse(req);
  } catch (e) {
    return { status: false, e };
  }
};

export const abtWebCloseTicket = async (ws, substatus = null) => {
  try {
    const command = {
      command: 'vcWebDeleteTicket',
      substatus,
    };
    const doReq = await doRequest(command, ws);
    return doReq;
  } catch (e) {
    return { status: false };
  }
};

export const abtWebFindTicket = async (currentTicketId, ws) => {
  if (currentTicketId) {
    const command = {
      command: 'vcWebFindTicket',
      data: { ticket_id: currentTicketId },
    };
    const doReq = await doRequest(command, ws);
    return doReq;
  }
  return { status: false };
};

export const abtWebAcceptInvite = async (currentTicketId, ws) => {
  const command = {
    command: 'vcWebSendBroadcast',
    data: { type: 'acceptSessionInvite', data: { ticket_id: currentTicketId } },
  };
  const doReq = await doRequest(command, ws);
  return doReq;
};

export const abtGetTicketInfo = async (currentTicketId, ws) => {
  if (currentTicketId) {
    const command = {
      command: 'cWSGetTicketInfo',
      idT: currentTicketId,
    };
    try {
      const req = await ws.sendMessage(command);
      const { ticket } = req;
      return prepareResponse(req, { ticket });
    } catch (erTake) {
      return { status: false, e: erTake };
    }
  }
  return { status: false };
};

export const abtSetClientType = async (clientType, ws) => {
  try {
    const req = await ws.sendMessage({
      command: 'cWSSetClientType',
      clientType,
    });
    if (req.status === 'ok') console.debug(`Set client type "${clientType}"`);

    return prepareResponse(req);
  } catch (e) {
    console.error(e);
    return { success: false, e };
  }
};

export const abtWebLogin = async (data, ws) => {
  try {
    // console.log('try setClientType === ');
    const { userName } = data;
    const reqClientType = await ws.sendMessage({
      command: 'cWSSetClientType',
      clientType: 'vcweb',
    });
    // console.log(' setClientType succes === ');
    if (reqClientType.status === 'ok') {
      // console.log('try abtWebLogin');
      console.debug('Set client type "vcweb"');
      const req = await ws.sendMessage({
        command: 'vcWebLogin',
        mediaSession: userName,
      });
      const { mediaSession } = req;
      // console.log('abtWebLogin succes === ');
      return prepareResponse(req, { mediaSession });
      // добивить проверку на ошибку req
    } throw new Error(reqClientType.error); // проверить на наличие reqClientType.error
  } catch (e) {
    return { success: false, e };
  }
};

export const abtCreateWebTicket = async (data, credentials, ws) => {
  try {
    const req = await ws.sendMessage({
      command: 'vcWebCreateTicket',
      data,
    });
    const { ticket } = req;
    return prepareResponse(req, ticket);
  } catch (e) {
    return { success: false, e };
  }
};

export const getWebTicketPosition = async (qId, ws) => {
  try {
    const req = await ws.sendMessage({
      command: 'vcWebGetTicketPosition',
      data: { q_id: qId },
    });
    const { position } = req;
    return prepareResponse(req, position);
  } catch (e) {
    return { success: false, e };
  }
};
