import React from 'react';
import appStore from '../../store';
import { startCall } from '../../store/infinity';

const MobileWelcomeScreen = () => {
  const allreadyCalled = appStore.useState((s) => s.allreadyCalled);
  const mediaAllowed = appStore.useState((s) => s.mediaAllowed);
  const isWorkTime = appStore.useState((s) => s.isWorkTime);
  const appParams = appStore.useState((s) => s.appParams);
  const isBanned = appStore.useState((s) => s.isBanned);
  const comment = appStore.useState((s) => s.banComment);

  const handleRequest = () => {
    window.location = appParams.backUrl;
  };

  return (
    <div className="mobile-welcome">
      {!mediaAllowed && (
      <p className="permissions__text-error">
        Просим предоставить доступ к микрофону, чтобы воспользоваться сервисом.
      </p>
      )}
      <div className="mobile-welcome__title">Добро пожаловать в систему</div>
      <div className="mobile-welcome__subtitle">Видеоконсультант</div>
      {allreadyCalled && <p className="text-error mb-3">Ваш талон был вызван. но вы не ответили. Пожалуйста, возьмите новый талон.</p>}
      {!isBanned && mediaAllowed && isWorkTime && (
      <button type="button" className="button button__accent mobile-welcome__leave-button" onClick={() => { startCall(); }}>
        Начать консультацию
      </button>
      )}
      {isBanned && (
      <p className="ban__message">
        У вас нет возможности получить видеоконсультацию по причине:
        <br />
        {comment}
      </p>
      )}
      {!isWorkTime && (
        <div className="worktime__message">
          <span style={{ marginLeft: 0 }} className="header__item-text">
            Время работы (мск): 10:00 - 20:00
          </span>
          <button className="mobile-button button__grey mobile-welcome__leave-button button__leave" type="button" onClick={handleRequest}>
            <h4>Оставить заявку</h4>
          </button>
        </div>
      )}
    </div>
  );
};

export default MobileWelcomeScreen;
