import React from 'react';
import appStore, { updateAppStore } from '../../store';
import MobileActiveCallScreen from './MobileActiveCallScreen';
import MobileWelcomeScreen from './MobileWelcomeScreen';
import MobileEndCallScreen from './MobileEndCallScreen';
import MobileWaitingScreen from './MobileWaitingScreen';
import MobileWaitDecisionScreen from './MobileWaitDecisionScreen';
import MobileRedirectScreen from './MobileRedirectScreen';
import MobileWrongBrowserScreen from './MobileWrongBrowserScreen';

const MobileScreenProvider = () => {
  const activeScreen = appStore.useState((s) => s.activeScreen);
  const isBanned = appStore.useState((s) => s.isBanned);

  if (isBanned) {
    updateAppStore({
      activeScreen: 'welcome',
    });
  }

  const getContent = () => {
    const result = {
      welcome: <MobileWelcomeScreen />,
      waiting: <MobileWaitingScreen />,
      wrongBrowserScreen: <MobileWrongBrowserScreen />,
      processTicket: <MobileActiveCallScreen />,
      endCall: <MobileEndCallScreen />,
      waitDecisionScreen: <MobileWaitDecisionScreen />,
      redirect: <MobileRedirectScreen />,
    //   welcome: <MobileWaitingScreen />,
    //   waiting: <MobileActiveCallScreen />,
    //   processTicket: <MobileWelcomeScreen />,
    //   endCall: <MobileEndCallScreen />,
    };

    if (!(activeScreen in result)) return <div>default</div>;
    return result[activeScreen];
  };

  return <div className="screen">{getContent()}</div>;
};

export default MobileScreenProvider;
