import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './mobile/mobileStyle/mobileStyle.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import getParamsFromUrl from './utils/getParamsFromUrl';
import { updateAppStore } from './store';

// createLogger()

const appParams = getParamsFromUrl(window.location.href);

updateAppStore({
  appParams: {
    title: 'ДЭК | Веб-консультант',
    theme: appParams.light ? 'light' : 'dark',
    dchat: !!appParams.dchat, // d = disable отключаем чат
    fs: !!appParams.fs, // фулскин
    dcontrols: !!appParams.dcontrols, // контролы
    dpreview: !!appParams.dpreview, // превью (своя камера, предпросмотор)
    dhaf: !!appParams.dhaf, // хедер и футер
    eDevices: !!appParams.edevices, // дивайсы (не уточнили)
    returnUrl: appParams.returnurl || 'https://dvec.ru', // ссылка возврата после завершиения обслуживания
    backUrl: appParams.backurl || 'https://asvk.dvec.ru/vcweb', // ссылка возврата после нажатия кнопки "не хочу ждать"
    qId: appParams.queue || null,
    source: (appParams.source) ? decodeURI(appParams.source) : '',
  },
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

serviceWorker.unregister();
