import React from 'react';
import {
  browserName, isMobile, isIOS, isTablet,
} from 'react-device-detect';
import appStore from '../../store/index';
import error from '../../images/icon/error.svg';

const copyLink = () => {
  let textarea;
  let result;
  const string = window.location.href;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
};

const WrongBrowserScreen = () => {
  const approvedBrowsers = appStore.useState((s) => s.approvedBrowsers);
  const approvedBrowsersList = () => {
    if ((isTablet || isMobile) && isIOS && browserName.indexOf('Safari') === -1) {
      return (
        <div style={{ margin: '4px 4px 4px 0px' }}>Safari</div>
      );
    }
    return approvedBrowsers.map((el) => (
      <div style={{ margin: '4px 4px 4px 0px' }} key={el}>{el}</div>
    ));
  };

  return (
    <div className="wrong-browser-screen">
      <div className="error-screen">
        <div style={{ padding: '8px' }}>
          <img id="error-img" src={error} alt="#" />
        </div>
        <div className="error-screen__browser-list">
          <h5>
            К сожалению, Ваш браузер не поддерживается
          </h5>
          <h3 style={{ margin: '8px 0 0 0', textAlign: 'left' }}>
            Пожалуйста, попробуйте любой браузер из списка ниже:
          </h3>
          <div className="error-screen__approved">
            {approvedBrowsersList()}
          </div>
          <button
            className="button error-screen__button"
            type="button"
            style={{ position: 'relative' }}
            onClick={copyLink}
          >
            <h4>Скопировать ссылку</h4>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WrongBrowserScreen;
