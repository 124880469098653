export async function sdpModifier(description) {
  if (!description.sdp)
    return description

  var lines = description.sdp.split("\n");


  var linesNew = [];

  var disableRTX = false;

  var audioCodecs = ["PCMU"];
  var videoCodecs = ["VP9"];

  if (audioCodecs.indexOf("*") >= 0)
    audioCodecs = [];
  if (videoCodecs.indexOf("*") >= 0)
    videoCodecs = [];

  var audioPayloads = [];
  var videoPayloads = [];

  var codecs = [];
  var payloads = [];
  var payload = "";

  var line = "";

  for (var i = 0; i < lines.length; i++) {
    line = lines[i];

    if (disableRTX) {
        if (line.indexOf("rtx/") >= 0) {
            i++;
            continue;
        }
        if (line.indexOf("red/") >= 0) {
            continue;
        }
    }

    if (line.indexOf("m=audio") === 0) {
        payload = "";
        codecs = audioCodecs;
        payloads = audioPayloads;
    }
    else if (line.indexOf("m=video") === 0) {
        payload = "";
        codecs = videoCodecs;
        payloads = videoPayloads;
    }

    if (codecs.length && line.indexOf("a=") === 0) {
        if (line.indexOf("a=rtpmap") === 0) {
            payload = line.split(":")[1].split(" ")[0];
            for (var c = 0; c < codecs.length; c++){
                if (line.indexOf(" " + codecs[c] + "/") >= 0) {
                    payloads.push(payload);
                }
            }
        }
        else {
            if (line.indexOf(":" + payload + " ") < 0) {
                payload = "";
            }
        }

        if (payload !== "") {
            var found = false;
            for (var d = 0; d < payloads.length; d++) {
                if (line.indexOf(":" + payloads[d] + " ") >= 0)
                    found = true;
            }
            if (!found)
                continue;
        }
    }
    linesNew.push(line);
  }

  for (var k = 0; k < linesNew.length; k++) {
    line = linesNew[k];

    payloads = [];

    if (line.indexOf("m=audio") === 0) {
        if (audioPayloads.length)
            payloads = audioPayloads;
    }
    else if (line.indexOf("m=video") === 0) {
        if (videoPayloads.length)
            payloads = videoPayloads;
    }

    if (payloads.length) {
        var items = line.trim().split(" ");
        var newItems = [];
        for (var j = 0; j < items.length; j++) {
            var item = items[j];
            if (j <= 2 || payloads.indexOf(item) >= 0)
                newItems.push(item);
        }
        line = newItems.join(" ");
        linesNew[k] = line;
    }
  }

  description.sdp = linesNew.join("\n");
  return description  
}