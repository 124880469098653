import React from 'react';

const WrongTimeScreen = () => (
  <div className="screen">
    <div className="error-screen" style={{ padding: '20px 20px' }}>
      <div>
        <h5 style={{ textAlign: 'center' }}>
          Извините, в данный момент операторы недоступны.
        </h5>
        <h3 style={{ marginTop: '10px 20px' }}>
          Время работы:
          {' '}
          <br />
          пн-вск 10:00 до 20:00
          {' '}
          <br />
          Ждем Вас в рабочее время
        </h3>
      </div>
    </div>
  </div>
);

export default WrongTimeScreen;
