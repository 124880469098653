import React, { useState, useEffect } from 'react';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import appStore from '../../store';

const EndCallScreen = () => {
  const currentTicketId = appStore.useState((s) => s.currentTicketId);
  const appConfig = appStore.useState((s) => s.appConfig);
  const appParams = appStore.useState((s) => s.appParams);
  const currentChatSession = appStore.useState((s) => s.currentChatSession);
  const { fileServerUrl } = appConfig;

  // const size = React.useContext(ResponsiveContext);

  const [quality, setQuality] = useState(0);
  const [email, setEmail] = useState('');

  const sendQuality = async () => {
    const qualityReq = await axios.post(
      `https://${appConfig.serverHost}/server/public/setMarkTicket`,
      {
        data: {
          ticketId: currentTicketId,
          ticketMark: quality,
        },
      },
    );
    const { data: { status } } = qualityReq;

    if (status === 'ok') {
      window.localStorage.removeItem('ticketId');
      // window.location.reload();
    }
  };

  const endSession = () => {
    window.localStorage.removeItem('ticketId');
    window.location = appParams.returnUrl;
  };

  // const saveChatFile = async () => {
  //   let fileContent = '';
  //   currentChatSession.forEach((msg) => {
  //     fileContent += `${msg.time} | ${msg.type} | `;
  //     fileContent += `${(msg.file) ? msg.file.downloadLink : msg.text} \r\n`;
  //   });
  //   const blob = new Blob([fileContent],
  //     { type: 'text/plain;charset=utf-8' });
  //   const a = document.createElement('a');
  //   a.href = URL.createObjectURL(blob);
  //   a.download = `${moment().format('YYYY-MM-DD')}-${currentTicketId}-chatsession.txt`;
  //   a.click();
  //   URL.revokeObjectURL(a.href);
  //   endSession();
  // };

  const saveChatEmail = async () => {
    if (email) {
      await axios({
        method: 'post',
        url: `${fileServerUrl}/api/media/sendChatEmail`,
        data: {
          email,
          chat: currentChatSession,
        },
      });
    }
    endSession();
  };

  useEffect(() => {
    if (quality > 0) {
      sendQuality();
    }
  }, [quality]);

  return (
    <div className="endcall">
      <div className="welcome__title">Спасибо за ваш звонок!</div>
      <div className="endcall__subtitle">Пожалуйста, оцените качество обслуживания.</div>
      <div className="content-box">
        <StarRatings
          rating={quality}
          starRatedColor="#f27026"
          starHoverColor="#f27026"
          changeRating={setQuality}
          numberOfStars={5}
          name="rating"
        />
      </div>
      <div className="endcall__form mt-5 mb-5">
        <div className="endcall__form-title mb-1">Хотите сохранить чат с оператором?</div>
        <input className="endcall__form-input" type="text" placeholder="Введите Email" onChange={(e) => setEmail(e.target.value)} />
      </div>
      <button
        type="button"
        className="button button__accent"
        style={{ margin: '0', bottom: '20%' }}
        onClick={saveChatEmail}
      >
        Отправить/завершить
      </button>
      {/* <div className="end-call-box mt-2"> */}
      {/*  <button type="button" className="button" onClick={saveChatEmail}> */}
      {/* <h4>Завершить обслуживание</h4></button> */}
      {/* </div> */}
    </div>
  );
};

export default EndCallScreen;
