/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { ReactComponent as MicOffIcon } from '../images/icons/mic_off.svg';
import { ReactComponent as MicIcon } from '../images/icons/mic.svg';
import { ReactComponent as CameraIcon } from '../images/icons/camera.svg';
import { ReactComponent as CaneraOffIcon } from '../images/icons/camera_off.svg';
import { ReactComponent as ChatIcon } from '../images/icons/chat.svg';
import { ReactComponent as EndCallIcon } from '../images/icons/endcall.svg';
import videoLoader from '../images/videoLoader.gif';
import { stopCall } from '../store/infinity';
import { toggle } from '../store/infinity/call';
import { LocalVideo, Video } from '../store/infinity/Video';

const MediaPlayer = (props) => {
  const { setShowChat, showChat } = props;
  const [showSelfCam, setShowSelfCam] = useState(true);
  const [muted, setMuted] = useState(true);

  const toggleChat = () => { setShowChat(!showChat); };


  const toogleSelfCam = async (e) => {
    e.stopPropagation();
    toggle('video');
    setShowSelfCam(!showSelfCam);
  };

  const toggleMicrophone = (e) => {
    e.stopPropagation();
    toggle('audio');
    setMuted(!muted);
  };

  return (
    <>
      <div className={`media__player-remote media__player-remote-fullscreen mobile-operator-cam ${(showChat) ? 'remote-video-small-container' : ''}`}>
        <Video
          poster={videoLoader}
          controls={false}
          autoPlay
          loop
          width="100%"
          height="100%"
          style={{ objectFit: 'cover', objectPosition: 'center' }}
          onClick={(e) => { e.preventDefault(); }}
        />
      </div>
      {showSelfCam && (
        <Draggable bounds="parent">
          <div
            className="mobile-media__player-self"
            style={{
              opacity: 1,
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <LocalVideo
              poster={videoLoader}
              controls={false}
              autoPlay
              loop
              muted
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', objectPosition: 'center' }}
              onClick={(e) => { e.preventDefault(); }}
            />
          </div>
        </Draggable>
      )}
      {!showChat
        && (
          <div className="mobile-media__player-controls">
            {/* <Timer type="Timer" active tick={1000} /> */}
            <button type="button" className="mobile-media__player-controls-button button-red" onClick={stopCall}>
              <EndCallIcon />
            </button>
            <button type="button" className="mobile-media__player-controls-button" onClick={toogleSelfCam}>
              {showSelfCam ? <CameraIcon /> : <CaneraOffIcon />}
            </button>
            <button type="button" className="mobile-media__player-controls-button" onClick={toggleMicrophone}>
              {muted ? <MicIcon /> : <MicOffIcon />}
            </button>
            <button type="button" className={`mobile-media__player-controls-button ${(showChat) && 'media__player-controls-button button-green'}`} onClick={toggleChat}>
              <ChatIcon />
            </button>
          </div>
        )}
    </>
  );
};

MediaPlayer.propTypes = {
  showChat: PropTypes.bool.isRequired,
  setShowChat: PropTypes.func.isRequired,
};
export default MediaPlayer;
