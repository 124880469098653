import React from 'react';

const ConnectionScreen = () => (
  <div className="welcome">
    <div className="welcome__title">Идет подключение</div>
    <div className="welcome__subtitle" />
    <p className="text-center mb-4">
      Пожалуйста, подождите...
    </p>
  </div>
);

export default ConnectionScreen;
