import appStore, { onInitMediaSession } from "..";
import { call, end } from "./call";
import { logout, registry } from "./phone";
import { subscribeEvents, unsubscribeEvents } from "./subscribeEvents";

export async function stopCall() {
  console.log('stopCall');
  await end();
  await logout();
  unsubscribeEvents();
}

export async function startCall() {
  appStore.update((s) => {
    s.activeScreen = 'waiting';
  })

  const sip = {
    domain: process.env.REACT_APP_SIP_DOMAIN,
    username: process.env.REACT_APP_SIP_NUMBER,
    password: process.env.REACT_APP_SIP_PASSWORD,
    operatorNum: process.env.REACT_APP_SIP_OPERATOR_NUM
  };

  const ws = {
    domain: process.env.REACT_APP_SIP_WS_DOMAIN,
    login: process.env.REACT_APP_SIP_WS_LOGIN,
    password: process.env.REACT_APP_SIP_WS_PASSWORD,
  }

  try {
    await registry(ws.domain, sip.domain, sip.username, sip.password);

    await call(sip.operatorNum, sip.domain, (callId) => {
      unsubscribeEvents();

      onInitMediaSession();

      subscribeEvents(ws.domain, ws.login, ws.password, sip.domain, callId);
    });
  } catch (error) {
    console.log("===========", error);
  }
}

