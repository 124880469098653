import { v4 } from 'uuid';

let inviter = null;
let remoteDisplayElements = {};
let localDisplayElements = {};

export const setInviter = (i) => inviter = i;

function startPlayElement(element) {
  element.load();
  element.play().catch(() => {});

  element.onload = () => {
    element.play().catch(() => {});
  };
}

export async function updateStreams() {
  if (!inviter) {
    return
  }
  // replaceTracks(inviter);

  const sessionDescriptionHandler = inviter.sessionDescriptionHandler;
  if (!sessionDescriptionHandler) {
    return;
  }

  for (const key of Object.keys(remoteDisplayElements)) {
    const stream = new MediaStream();

    const element = remoteDisplayElements[key];

    element.srcObject = sessionDescriptionHandler.remoteMediaStream;

    startPlayElement(element);

    stream.onaddtrack = () => {
      startPlayElement(element);
    }

    stream.onremovetrack = () => {
      startPlayElement(element);
    }
  }

  for (const key of Object.keys(localDisplayElements)) {
    const stream = new MediaStream();

    const element = localDisplayElements[key];

    element.srcObject = sessionDescriptionHandler.localMediaStream;

    startPlayElement(element);

    stream.onaddtrack = () => {
      startPlayElement(element);
    }

    stream.onremovetrack = () => {
      startPlayElement(element);
    }
  }
}

export function addRemoteDisplayElement(element) {
  const id = v4();

  remoteDisplayElements[id] = element;

  updateStreams();

  return id;
}

export function removeRemoteDisplayElement(id) {
  delete remoteDisplayElements[id];
}

export function addLocaldisplayElement(element) {
  const id = v4();

  localDisplayElements[id] = element;

  updateStreams();

  return id;
}

export function removeLocalDisplayElement(id) {
  delete localDisplayElements[id];
}
