import React from 'react';
import appStore, { getTicket } from '../../store';
import { startCall } from '../../store/infinity';

const WelcomeScreen = () => {
  const allreadyCalled = appStore.useState((s) => s.allreadyCalled);
  const mediaAllowed = appStore.useState((s) => s.mediaAllowed);
  const isWorkTime = appStore.useState((s) => s.isWorkTime);
  const appParams = appStore.useState((s) => s.appParams);
  const isBanned = appStore.useState((s) => s.isBanned);
  const comment = appStore.useState((s) => s.banComment);
  const handleRequest = () => {
    window.location = appParams.backUrl;
  };

  return (
    <div className="welcome">
      <div className="welcome__title">Добро пожаловать в систему</div>
      <div className="welcome__subtitle">Видеоконсультант</div>
      {!mediaAllowed && (
        <p className="text-error">
          Просим предоставить доступ к микрофону, чтобы воспользоваться сервисом.
        </p>
      )}
      {allreadyCalled && <p className="text-error mb-3">Ваш талон был вызван. но вы не ответили. Пожалуйста, возьмите новый талон.</p>}
      {mediaAllowed && isWorkTime && !isBanned && (
        <button type="button" className="button button__accent" onClick={() => { startCall(); }}>
          Начать консультацию
        </button>
      )}
      {isBanned && (
        <p className="ban__message">
          У вас нет возможности получить видеоконсультацию по причине:
          <br />
          {comment}
        </p>
      )}
      {!isWorkTime && (
      <div className="worktime__message">
        <span className="header__item-text">
          Время работы (мск): 10:00 - 20:00
        </span>
        <button className="button button__grey button__leave" type="button" onClick={handleRequest}>
          <h4>Оставить заявку</h4>
        </button>
      </div>
      )}
    </div>
  );
};

export default WelcomeScreen;
