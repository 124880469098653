import { Store } from 'pullstate';
import { RegistererState, SessionState, TransportState } from 'sip.js';

export const PhoneStore = new Store({
  domain: "",
  netStatus: TransportState.Disconnected,
  regStatus: RegistererState.Initial,
  errorRegistration: null,
  callStatus: SessionState.Initial,
  printRequests: [],
  isAccepted: false,
});