/* eslint-disable no-param-reassign */
import { Store } from 'pullstate';
// import axios from 'axios';
import {
  abtWebAcceptInvite, abtWebLogin, abtWebFindTicket, abtCreateWebTicket,
} from '../utils/abtSocketLibClient';
import webSocketClient from '../utils/webSocketClient';
import randomId from '../utils/randomId';
import getConfig from '../utils/getAppConfig';
import nocamimage from '../images/no-camera.png';
import initMediaSession from '../utils/openViduClient';

const initNoCameraCanvas = () => {
  // для дебичей,
  // эта функция рисует конвас,
  // снизу цикл добаляется для того, что бы конвас можно
  // было использовать в качетве трека для стрима!

  const canvas = document.createElement('canvas');
  canvas.id = 'NoVideo';
  canvas.width = 640;
  canvas.height = 480;
  const ctx = canvas.getContext('2d');

  ctx.fillStyle = '#EDEDED';
  ctx.fillRect(0, 0, 640, 480);

  const image = new Image();
  image.src = nocamimage;
  image.onload = () => {
    ctx.drawImage(image, 260, 180);
  };
  const loop = () => {
    ctx.drawImage(image, 260, 180);
    setTimeout(loop, 1000 / 30); // drawing at 30fps
  };
  loop();
  return canvas;
};

const appStore = new Store({
  appInit: false,
  mediaAllowed: false,
  mediaServerStatus: false,
  allreadyCalled: false,
  noVideoCanvas: initNoCameraCanvas(),
  appConfig: getConfig(),
  controlSocket: null,
  isSocketInit: false,
  mediaSession: null,
  currentTicketId: null,
  activeTicket: {},
  sessionId: null,
  activeScreen: 'welcome',
  isWorkTime: true,
  ip: '',
  city: '',
  browser: '',
  os: '',
  authUser: {
    userId: null,
    userFio: null,
    userType: 'client',
  },
  ovdSessionId: null,
  ovdSession: null,
  ovdPublisher: null,
  ovdSubscribers: [],
  appParams: {
    dchat: false, // d = disable отключаем чат
    fs: false, // фулскин
    dcontrols: false, // контролы
    dpreview: false, // превью (своя камера, предпросмотор)
    dhaf: false, // хедер и футер
    eDevices: false, // дивайсы (не уточнили)
  },
  runningLines: [],
  currentChatSession: [],
  mediaDevices: {
    audio: localStorage.getItem('avc-audio'),
    video: localStorage.getItem('avc-video'),
  },
  approvedBrowsers: ['Chrome', 'Samsung Browser', 'Edge', 'Opera', 'Safari', 'Yandex', 'Mobile Safari'],
  isBanned: false,
  banComment: '',
});
export default appStore;

export const getAppConfig = () => {
  const cState = appStore.getRawState();
  return cState.appConfig;
};

export const isLoggedIn = () => {
  const cState = appStore.getRawState();
  return (cState.authUser.userId);
};

export const onAddSubscriber = (subscriber) => {
  console.log('onAddSubscriber === ', subscriber);
  const cState = appStore.getRawState();
  const subscribers = [...cState.ovdSubscribers, subscriber];
  appStore.update((s) => {
    s.ovdSubscribers = subscribers;
  });
};

export const onAddPublisher = (publisher) => {
  appStore.update((s) => {
    s.ovdPublisher = publisher;
  });
};

export const onInitMediaSession = () => {
  appStore.update((s) => {
    s.activeScreen = 'processTicket';
  });
  // appStore.update({ iviteToCall: true, currentWorkState: 'processTicket' });
};

export const onSessionDisconnect = async (accepted) => {
  console.log('отработал  onSessionDisconnect=== ');
  appStore.update((s) => { s.activeScreen = accepted ? 'endCall' : 'endCall'; });
};

export const onStreamDestroyed = async (event) => {
  const cState = appStore.getRawState();
  console.info(event, cState.ovdSubscribers);
  // cState.ovdSession.disconnect();
  appStore.update((s) => {
    s.ovdSubscribers = [];
  });
};

export const onChatMessage = (message) => {
  // console.log('message === ', message);

  const cState = appStore.getRawState();
  const messages = [...cState.currentChatSession, message];
  appStore.update((s) => {
    // eslint-disable-next-line no-param-reassign
    s.currentChatSession = messages;
  });
};

export const updateAppStore = (values) => {
  // console.debug('UPDATE STORE', values);
  const updatedValues = (s) => {
    Object.keys(values).forEach((key) => {
      s[key] = values[key];
    });
  };
  appStore.update(updatedValues);
};

export const acceptSessionInvite = async (sessionId) => {
  const cState = appStore.getRawState();
  if (sessionId !== `ticket-${cState.currentTicketId}`) return;
  const acceptInvite = await abtWebAcceptInvite(cState.currentTicketId, cState.controlSocket);
  if (!acceptInvite) {
    console.error(acceptInvite);
  }
};

export const updateMediaDevice = async (type, deviceId) => {
  const cState = appStore.getRawState();
  const mDevices = { ...cState.mediaDevices };
  mDevices[type] = deviceId;
  appStore.update((s) => {
    // eslint-disable-next-line no-param-reassign
    s.mediaDevices = mDevices;
  });
  localStorage.setItem(`avc-${type}`, deviceId);
};

export const getTicket = async () => {
  const {
    controlSocket, appConfig, authUser, appParams, ip, os, city, browser, isBanned,
  } = appStore.getRawState();

  const data = {
    q_id: appParams.qId || appConfig.queueId,
    source: appParams.source,
    zone_id: appConfig.zoneId,
    fullname: authUser.userFio,
  };
  // console.log(data.q_id);
  const credentials = {
    address: ip,
    browser,
    os,
    city,
  };

  if (!isBanned) {
    try {
      const tryGetTicket = await abtCreateWebTicket(data, credentials, controlSocket);
      const { success, e } = tryGetTicket;
      if (success) {
        updateAppStore({
          currentTicketId: tryGetTicket.data.id,
          allreadyCalled: false,
          activeScreen: 'waiting',
        });
        localStorage.setItem('ticketId', tryGetTicket.data.id);
      } else {
        if (e.code === '56' || e.code === '55') {
          updateAppStore({ isWorkTime: false });
        }
        updateAppStore({ currentTicketId: null });
        console.error('Ошибка abrCreateWebTicket (получания талона)');
      }
      return { status: true };
    } catch (e) {
      return { status: false, e };
    }
  }

  return { status: false, e: 'banned' };
};

export const recoverTicket = async (ticketId) => {
  const { controlSocket } = appStore.getRawState();
  try {
    const findTicketReq = await abtWebFindTicket(ticketId, controlSocket);
    const { success } = findTicketReq;
    if (success) {
      updateAppStore({ currentTicketId: Number(ticketId), activeScreen: 'waiting' });
    } else {
      // updateAppStore({ activeScreen: 'welcome', allreadyCalled: true });
      window.localStorage.removeItem('ticketId');
      getTicket();
      // тут можно записать ошибку о том что с талоном что-то не ладно
    }
  } catch (e) {
    console.error(e);
  }
};
export const initUserConnection = async (userInfo, controlSocket) => {
  // console.log('start initUserConnection=== ');
  const tryLogin = await abtWebLogin(userInfo, controlSocket);
  const { success, data } = tryLogin;
  if (success) {
    // console.log('Login success=== ');
    appStore.update((s) => {
      s.isSocketInit = true;
      s.appInit = true;
      s.controlSocket = controlSocket;
      s.mediaSession = data.mediaSession;
      s.authUser = {
        userId: userInfo.userName,
        userFio: userInfo.userFio,
        userType: 'client',
      };
      // s.activeScreen = existTicket ? 'waiting' : 'welcome';
    });
  }
};
