import React from 'react';
import appStore, { updateAppStore } from '../../store';
import ActiveCallScreen from './ActiveCallScreen';
import EndCallScreen from './EndCallScreen';
import WaitingScreen from './WaitingScreen';
import WelcomeScreen from './WelcomeScreen';
import WrongBrowserScreen from './WrongBrowserScreen';
import WrongTimeScreen from './WrongTimeScreen';
import WaitDecisionScreen from './WaitDecisionScreen';
import RedirectScreen from './RedirectScreen';
import ConnectionScreen from './ConnectionScreen';

const ScreensProvider = () => {
  const activeScreen = appStore.useState((s) => s.activeScreen);
  const isBanned = appStore.useState((s) => s.isBanned);

  if (isBanned) {
    updateAppStore({
      activeScreen: 'welcome',
    });
  }

  const getContent = () => {
    const result = {
      welcome: <WelcomeScreen />,
      wrongBrowserScreen: <WrongBrowserScreen />,
      waiting: <WaitingScreen />,
      processTicket: <ActiveCallScreen />,
      wrongTime: <WrongTimeScreen />,
      endCall: <EndCallScreen />,
      waitDecisionScreen: <WaitDecisionScreen />,
      redirect: <RedirectScreen />,
      connection: <ConnectionScreen />,
    };
    // TODO
    //   welcome: <WelcomeScreen />,
    //   wrongBrowserScreen: <WrongBrowserScreen />,
    //   waiting: <WaitingScreen />,
    //   processTicket: <ActiveCallScreen />,
    //   wrongTime: <WrongTimeScreen />,
    //   endCall: <EndCallScreen />,
    if (!(activeScreen in result)) return <div>default</div>;
    return result[activeScreen];
  };

  return <div className="screen">{getContent()}</div>;
};

export default ScreensProvider;
