import React, { useEffect } from 'react';
import axios from 'axios';
import {
  browserName, isMobile, isAndroid,
} from 'react-device-detect';
import Logo from './images/logo.png';
import ClockIcon from './images/icons/clock.svg';
import pingMediaServer from './utils/pingMediaServer';
import ScreensProvider from './components/screens/ScreensProvider';
import MobileScreenProvider from './mobile/screens/MobileScreenProvider';
import appStore, {
  updateAppStore,
  recoverTicket, getTicket,
} from './store';
// import LogoDark from './images/logo-dark.svg';
// import Logo from './images/logo.svg';

const MEDIA_PING_TIMEOUT = 10000;

const App = () => {
  const approvedBrowsers = appStore.useState((s) => s.approvedBrowsers);
  const activeScreen = appStore.useState((s) => s.activeScreen);

  const browserIspect = () => approvedBrowsers.includes(browserName);

  // const timeValid = checkTime();
  const chekBrowser = browserIspect();
  const appInit = appStore.useState((s) => s.appInit);
  const appParams = appStore.useState((s) => s.appParams);
  const appConfig = appStore.useState((s) => s.appConfig);

  const { qId } = appParams;

  if (!chekBrowser) updateAppStore({ activeScreen: 'wrongBrowserScreen' });
  // if (!timeValid) updateAppStore({ activeScreen: 'wrongTime' });

  const checkMicroAwailable = async () => {
    const existTicket = localStorage.getItem('ticketId');
    const userStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: false,
        autoGainControl: false,
      },
      video: false,
    });
    if (userStream) {
      updateAppStore({ mediaAllowed: true });
      if (existTicket) recoverTicket(existTicket);
      if (!existTicket && qId) getTicket();
    } else {
      updateAppStore({ mediaAllowed: false });
    }
  };

  useEffect(() => {
    checkMicroAwailable();

      // const inviteToCallSubscription = appStore
      //   .subscribe((s) => s.sessionId, (sessionId, allState) => {
      //     if (sessionId === `ticket-${allState.currentTicketId}`) {
      //       if (!allState.ovdSession) {
      //         console.log('CONNECT TO SESSION');
      //         const OV = initMediaSession(
      //           allState.appConfig.serverHost,
      //           allState.currentTicketId,
      //           onAddSubscriber,
      //           onAddPublisher,
      //           onInitMediaSession,
      //           onStreamDestroyed,
      //           onSessionDisconnect,
      //           onChatMessage,
      //           allState.mediaDevices,
      //         );
      //         updateAppStore({ activeScreen: 'connection', sessionId: null, OV });
      //       }
      //     }
      //   });
      //
      // return () => { inviteToCallSubscription(); };
  }, [appInit]);

  const getRunningLines = async () => {
    const response = await axios({
      method: 'get',
      url: `https://${appConfig.serverHost}/api/runninglines`,
    });

    const { data } = response;
    updateAppStore({ runningLines: data });
  };

  useEffect(() => {
    if (chekBrowser) {
      if (isMobile && isAndroid) {
        setTimeout(() => {
          const { innerWidth: width, innerHeight: height } = window;
          const realWidth = Math.min(width, height);
          const realHeigth = Math.max(width, height);
          const viewport = document.querySelector('meta[name=viewport]');
          viewport.setAttribute('content', `height=${realHeigth}, width=${realWidth}, initial-scale=0.86, maximum-scale=3.0, minimum-scale=0.86`);
        }, 50);
      }

      pingMediaServer();
      const pingInterval = setInterval(() => { pingMediaServer(); }, MEDIA_PING_TIMEOUT);
      getRunningLines();

      return () => {
        clearInterval(pingInterval);
        const viewport = document.querySelector('meta[name=viewport]');
        viewport.setAttribute('content', 'height=device-height, width=device-width, initial-scale=1');
      };
    } return undefined;
  }, []);
  return (
    <div className={`layout ${(appParams.theme === 'light') ? 'theme-light' : 'theme-dark'}`}>
      <p className={isMobile ? 'appversion mobile' : 'appversion'}>
        v.
        {process.env.REACT_APP_VERSION}
      </p>
      {isMobile ? (
        <div className="container">
          <div className={`header header__padding-min ${activeScreen === 'processTicket' ? 'header__mobile' : ''}`}>
            <div className="header__logo header__logo_mobile" style={{ height: '32px' }}>
              <img src={Logo} alt="logo" />
            </div>
            <div className={`header__item ${activeScreen === 'processTicket' ? 'header__item-hidden' : ''}`}>
              <img src={ClockIcon} alt="clock-icon" />
              <span className="header__item-text">
                Время работы: 09:00 - 19:00
              </span>
            </div>
          </div>
          <MobileScreenProvider />
        </div>
      )
        : (
          <div className="container">
            <div className="header">
              <div className="header__logo" style={{ height: '32px' }}>
                <img src={Logo} alt="logo" />
              </div>
              <div className={`header__item ${activeScreen === 'processTicket' ? 'header__item-hidden' : ''}`}>
                <img src={ClockIcon} alt="clock-icon" />
                <span className="header__item-text">
                  Время работы: 09:00 - 19:00
                </span>
              </div>
            </div>
            <ScreensProvider />
          </div>
        )}
    </div>
  );
};

export default App;
